<template>
  <div class="row">
    <!-- <div class="col-md-11 col-xl-12">
      <div class="d-flex align-items-md-center mb-2 flex-column flex-md-row">
        <div class="bg-white rounded p-4 d-flex flex-grow-1 flex-sm-grow-0">
        
          <form
            class="
              form
              d-flex
              align-items-md-center
              flex-sm-row flex-column flex-grow-1 flex-sm-grow-0
            "
          >
         
            <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
              <span class="svg-icon svg-icon-lg">
                
                <inline-svg src="media/svg/icons/General/Search.svg" />
                
              </span>
              <input
                type="text"
                v-model="searchID"
                @input="onchangeSiteId()"
                class="form-control border-0 font-weight-bold pl-2"
                placeholder="Search ID"
              />
            </div>
          
            <span class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"></span>
            <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
              <span class="svg-icon svg-icon-lg">
               
                <inline-svg src="media/svg/icons/General/Search.svg" />
                
              </span>
              <input
                type="text"
                v-model="wsid"
                @input="onchangeWSId()"
                class="form-control border-0 font-weight-bold pl-2"
                placeholder="Search WSID"
              />
            </div>
           
            <span class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"></span>
            <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
              <span class="svg-icon svg-icon-lg">
                
                <inline-svg src="media/svg/icons/General/Search.svg" />
               
              </span>
              <input
                type="text"
                v-model="location"
                @input="onchangeLocation()"
                class="form-control border-0 font-weight-bold pl-2"
                placeholder="Search Location"
              />
            </div>
            
            <span class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"></span>
            <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
              <span class="svg-icon svg-icon-lg">
                
                <inline-svg src="media/svg/icons/General/Search.svg" />
                
              </span>
              <input
                type="text"
                v-model="boxSn"
                @input="onchangeBox()"
                class="form-control border-0 font-weight-bold pl-2"
                placeholder="Search Box SN"
              />
            </div>
           
            <span class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"></span>
            <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
              <span class="svg-icon svg-icon-lg">
               
                <inline-svg src="media/svg/icons/General/Search.svg" />
                
              </span>
              <input
                type="text"
                v-model="className"
                @input="onchangeClassName()"
                class="form-control border-0 font-weight-bold pl-2"
                placeholder="Search Class Name"
              />
            </div>
            <button
              type="submit"
              class="
                btn btn-dark
                font-weight-bold
                btn-hover-light-primary
                mt-3 mt-sm-0
                px-7
              "
            >
              Search
            </button>
          </form>
         
        </div>
       
        <div class="mt-4 my-md-0 mx-md-10">
          <a class="text-dark font-weight-bolder text-hover-primary"
            >Clear All</a
          >
        </div>
       
      </div>
    </div> -->
    <div class="col-md-11 col-xl-12">
      <div class="card card-custom mb-3">
        <div class="card-header" style="border-color: var(--bordercolorform)">
          <div class="card-title">
            <h3 class="card-label cardLabelTiket">Site Data</h3>
          </div>
          <div class="card-toolbar">
            <b-button
              variant="danger"
              @click.prevent.stop="showModalAdd = true"
            >
              <b-icon-plus></b-icon-plus> Add Site
            </b-button>
          </div>
        </div>
        <div style="margin: 12px">
          <DxDataGrid
            :ref="my - data - grid"
            :data-source="itemsadvanced"
            key-expr="site_id"
            :show-borders="true"
            :word-wrap-enabled="false"
            :column-auto-width="false"
            :remote-operations="true"
            :column-hiding-enabled="true"
            @exporting="onExporting"
          >
            <DxSearchPanel :visible="true" />
            <DxFilterRow :visible="false" />
            <DxExport :enabled="true" />
            <DxColumn data-field="site_id" caption="Site ID" :width="100" />
            <DxColumn data-field="wsid" caption="Ws ID" :width="150" />
            <DxColumn data-field="location_name" caption="Location Name" />
            <DxColumn data-field="box_sn" caption="Box SN" />
            <DxColumn data-field="site_class_name" caption="Class Name" />
            <DxColumn data-field="address" caption="Address" />
            <DxColumn data-field="province" caption="Province" />
            <DxColumn data-field="district" caption="District" />
            <DxColumn data-field="zip_code" caption="Zip Code" />
            <DxColumn data-field="pkt" caption="PKT" />
            <DxColumn data-field="customer.name" caption="Customer" />
            <DxColumn :width="100" type="buttons" caption="Aksi">
              <DxButton icon="fa fa-eye" hint="Lihat" :onClick="iconAdvanced" />
              <DxButton icon="fa fa-trash" hint="Hapus" :onClick="hapus" />
              <DxButton icon="fa fa-edit" hint="Edit" :onClick="iconEdit" />
            </DxColumn>
            <DxPaging :page-size="10" />
            <DxPager
              :visible="true"
              :show-page-size-selector="showPageSizeSelector"
              :allowed-page-sizes="pageSizes"
            />
          </DxDataGrid>
        </div>
        <b-modal
          v-model="showModalEditadv"
          title="Detail Advanced"
          hide-footer
          scrollable
          size="lg"
          id="edit-user-modal-adv"
        >
          <b-container fluid>
            <b-row class="mb-2">
              <b-col cols="3">Site ID</b-col>
              <b-col>
                {{ detaildataadv.site_id }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">WS ID</b-col>
              <b-col>
                {{ detaildataadv.wsid }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Location Name</b-col>
              <b-col>
                {{ detaildataadv.location_name }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Box SN</b-col>
              <b-col>
                {{ detaildataadv.box_sn }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Site Class Name</b-col>
              <b-col>
                {{ detaildataadv.site_class_name }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Address</b-col>
              <b-col>
                {{ detaildataadv.address }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Province</b-col>
              <b-col>
                {{ detaildataadv.province }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">District</b-col>
              <b-col>
                {{ detaildataadv.district }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">Zip Code</b-col>
              <b-col>
                {{ detaildataadv.zip_code }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3">PKT</b-col>
              <b-col>
                {{ detaildataadv.pkt }}
              </b-col>
            </b-row>
            <!--                <b-row class="mb-2">-->
            <!--                  <b-col cols="3">Customer</b-col>-->
            <!--                  <b-col>-->
            <!--                    {{ detaildataadv.customer.id }}-->
            <!--                  </b-col>-->
            <!--                </b-row>-->
          </b-container>
          <template #modal-footer>
            <div class="w-100">
              <b-button
                variant="danger"
                size="sm"
                class="float-right"
                @click="showModalEditadv = false"
              >
                Close
              </b-button>
            </div>
          </template>
        </b-modal>
      </div>
    </div>
    <!--   Tambah New User   -->
    <b-modal
      v-model="showModalAdd"
      hide-footer
      centered
      scrollable
      size="lg"
      id="add-new-modal"
    >
      <template #modal-header="{ close }" class="text-center">
        <h3>Add Site</h3>
      </template>
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group id="example-input-group-1" label-for="example-input-1">
          <template v-slot:label>
            Site ID<span class="text-danger">*</span>
          </template>
          <b-form-input
            id="input-name"
            name="input-name"
            placeholder="Site ID"
            v-model="$v.form.site_id.$model"
            :state="validateState('site_id')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback"
            >Site ID harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-3"
          label-for="input-name"
          label="WS ID"
        >
          <b-form-input
            id="input-name"
            name="input-name"
            placeholder="WS ID"
            v-model="$v.form.ws_id.$model"
            :state="validateState('ws_id')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-1-live-feedback"
            >WS ID harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-3"
          label-for="input-name"
          label="Location Name"
        >
          <b-form-input
            id="input-name"
            name="input-name"
            placeholder="Location Name"
            v-model="$v.form.location.$model"
            :state="validateState('location')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-1-live-feedback"
            >Location harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-3"
          label-for="input-name"
          label="Box SN"
        >
          <b-form-input
            id="input-name"
            name="input-name"
            placeholder="Box SN"
            v-model="$v.form.boxsn.$model"
            :state="validateState('boxsn')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-1-live-feedback"
            >Box SN harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-3"
          label-for="input-name"
          label="Class Name"
        >
          <b-form-input
            id="input-name"
            name="input-name"
            placeholder="Class Name"
            v-model="$v.form.classname.$model"
            :state="validateState('classname')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-1-live-feedback"
            >Class Name harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-3"
          label-for="input-name"
          label="Address"
        >
          <b-form-input
            id="input-name"
            name="input-name"
            placeholder="Address"
            v-model="$v.form.address.$model"
            :state="validateState('address')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-1-live-feedback"
            >Address harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-3"
          label-for="input-name"
          label="Province"
        >
          <b-form-select
            id="select-province"
            v-model="$v.form.province.$model"
            :options="optionprov"
            :state="validateState('province')"
            aria-describedby="input-role-live-feedback"
          >
          </b-form-select>
          <b-form-invalid-feedback id="input-1-live-feedback"
            >Province harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-3"
          label-for="input-name"
          label="District"
        >
          <b-form-input
            id="input-name"
            name="input-name"
            placeholder="District"
            v-model="$v.form.district.$model"
            :state="validateState('district')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-1-live-feedback"
            >District harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-3"
          label-for="input-name"
          label="Zip Code"
        >
          <b-form-input
            id="input-name"
            name="input-name"
            type="number"
            placeholder="Zip Code"
            v-model="$v.form.zip_code.$model"
            :state="validateState('zip_code')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-1-live-feedback"
            >Zip Code harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-3"
          label-for="input-name"
          label="Pkt"
        >
          <b-form-input
            id="input-name"
            name="input-name"
            placeholder="PKT"
            v-model="$v.form.pkt.$model"
            :state="validateState('pkt')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-1-live-feedback"
            >Pkt harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-3"
          label-for="input-name"
          label="Customer"
        >
          <b-form-select
            id="select-customer"
            v-model="$v.form.customer.$model"
            :options="optionscustomer"
            :state="validateState('customer')"
            aria-describedby="input-role-live-feedback"
          >
          </b-form-select>
          <b-form-invalid-feedback id="input-1-live-feedback"
            >Customer harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <hr />
        <div class="d-flex justify-content-center">
          <button
            class="btn btn-cancel mr-8"
            type="button"
            @click="onCancel()"
          >
            Kembali
          </button>
          <button type="submit" class="btn btn-add">Simpan</button>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      v-model="showModalEdit"
      hide-footer
      centered
      scrollable
      size="lg"
      id="edit-new-modal"
    >
      <template #modal-header="{ close }" class="text-center">
        <h3>Edit Site</h3>
      </template>
      <b-form @submit.stop.prevent="onSubmitEdit">
        <b-form-group id="example-input-group-1" label-for="example-input-1">
          <template v-slot:label>
            Site ID<span class="text-danger">*</span>
          </template>
          <b-form-input
            id="input-name"
            name="input-name"
            placeholder="Site ID"
            v-model="$v.formedit.site_id.$model"
            :state="validateStateFormEdit('site_id')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback"
            >Site ID harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-3"
          label-for="input-name"
          label="WS ID"
        >
          <b-form-input
            id="input-name"
            name="input-name"
            placeholder="WS ID"
            v-model="$v.formedit.ws_id.$model"
            :state="validateStateFormEdit('ws_id')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-1-live-feedback"
            >WS ID harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-3"
          label-for="input-name"
          label="Location Name"
        >
          <b-form-input
            id="input-name"
            name="input-name"
            placeholder="Location Name"
            v-model="$v.formedit.location.$model"
            :state="validateStateFormEdit('location')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-1-live-feedback"
            >Location harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-3"
          label-for="input-name"
          label="Box SN"
        >
          <b-form-input
            id="input-name"
            name="input-name"
            placeholder="Box SN"
            v-model="$v.formedit.boxsn.$model"
            :state="validateStateFormEdit('boxsn')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-1-live-feedback"
            >Box SN harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-3"
          label-for="input-name"
          label="Class Name"
        >
          <b-form-input
            id="input-name"
            name="input-name"
            placeholder="Class Name"
            v-model="$v.formedit.classname.$model"
            :state="validateStateFormEdit('classname')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-1-live-feedback"
            >Class Name harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-3"
          label-for="input-name"
          label="Address"
        >
          <b-form-input
            id="input-name"
            name="input-name"
            placeholder="Address"
            v-model="$v.formedit.address.$model"
            :state="validateStateFormEdit('address')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-1-live-feedback"
            >Address harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-3"
          label-for="input-name"
          label="Province"
        >
          <b-form-select
            id="select-province"
            v-model="$v.formedit.province.$model"
            :options="optionprov"
            :state="validateStateFormEdit('province')"
            aria-describedby="input-role-live-feedback"
          >
          </b-form-select>
          <b-form-invalid-feedback id="input-1-live-feedback"
            >Province harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-3"
          label-for="input-name"
          label="District"
        >
          <b-form-input
            id="input-name"
            name="input-name"
            placeholder="District"
            v-model="$v.formedit.district.$model"
            :state="validateStateFormEdit('district')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-1-live-feedback"
            >District harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-3"
          label-for="input-name"
          label="Zip Code"
        >
          <b-form-input
            id="input-name"
            name="input-name"
            type="number"
            placeholder="Zip Code"
            v-model="$v.formedit.zip_code.$model"
            :state="validateStateFormEdit('zip_code')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-1-live-feedback"
            >Zip Code harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-3"
          label-for="input-name"
          label="Pkt"
        >
          <b-form-input
            id="input-name"
            name="input-name"
            placeholder="PKT"
            v-model="$v.formedit.pkt.$model"
            :state="validateStateFormEdit('pkt')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-1-live-feedback"
            >Pkt harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="example-input-group-3"
          label-for="input-name"
          label="Customer"
        >
          <b-form-select
            id="select-customer"
            v-model="$v.formedit.customer.$model"
            :options="optionscustomer"
            :state="validateStateFormEdit('customer')"
            aria-describedby="input-role-live-feedback"
          >
          </b-form-select>
          <b-form-invalid-feedback id="input-1-live-feedback"
            >Customer harus diisi.
          </b-form-invalid-feedback>
        </b-form-group>
        <hr />
        <div class="d-flex justify-content-center">
          <button
            class="btn btn-cancel mr-8"
            type="button"
            @click="$bvModal.hide('edit-new-modal')"
          >
            Kembali
          </button>
          <button type="submit" class="btn btn-add">Simpan</button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxSearchPanel,
  DxFilterRow,
  DxColumn,
  DxButton,
  DxExport
} from "devextreme-vue/data-grid";
import { validationMixin } from "vuelidate";
import { customers } from "../../mockjson";
import WebService from "../../core/services/api";
import EventBus from "../../core/services/common/EventBus";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import Axios from "axios";
export default {
  mixins: [validationMixin],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DxDataGrid,
    DxSearchPanel,
    DxFilterRow,
    DxColumn,
    DxButton,
    DxExport
  },
  data() {
    return {
      dataSource: customers,
      form: {
        site_id: "",
        ws_id: "",
        location: "",
        boxsn: "",
        classname: "",
        address: "",
        province: "",
        district: "",
        zip_code: "",
        pkt: "",
        customer: ""
      },
      formedit: {
        site_id: "",
        ws_id: "",
        location: "",
        boxsn: "",
        classname: "",
        address: "",
        province: "",
        district: "",
        zip_code: "",
        pkt: "",
        customer: ""
      },
      optionprov: [],
      optionscustomer: [],
      detaildata: "",
      detaildataadv: "",
      wsid: "",
      searchID: "",
      location: "",
      showModalAdd: false,
      showModalEdit: false,
      boxSn: "",
      className: "",
      searchQuery: null,
      editvisit: false,
      showModalEditadv: false,
      itemsuser: [],
      itemsadvanced: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: ""
      },
      infoModaladv: {
        id: "info-modal",
        title: "",
        content: ""
      },
      selectedstatdev: null,
      selectedstatusvisit: null,

      optionsstatusvisit: [
        { value: null, text: "Select Status Device" },
        { value: "a", text: "Active" },
        { value: "b", text: "Non Active" },
        { value: "c", text: "Pending Device" },
        { value: "d", text: "--" }
      ],
      optionsstatdev: [
        { value: null, text: "Select Status Visit" },
        { value: "a", text: "Priority" },
        { value: "b", text: "Priority" },
        { value: "c", text: "Priority" },
        { value: "d", text: "Priority" }
      ]
    };
  },
  validations: {
    form: {
      site_id: {
        required
      },
      ws_id: {
        required
      },
      location: {
        required
      },
      boxsn: {
        required
      },
      classname: {
        required
      },
      address: {
        required
      },
      province: {
        required
      },
      district: {
        required
      },
      zip_code: {
        required
      },
      pkt: {
        required
      },
      customer: {
        required
      }
    },
    formedit: {
      site_id: {
        required
      },
      ws_id: {
        required
      },
      location: {
        required
      },
      boxsn: {
        required
      },
      classname: {
        required
      },
      address: {
        required
      },
      province: {
        required
      },
      district: {
        required
      },
      zip_code: {
        required
      },
      pkt: {
        required
      },
      customer: {
        required
      }
    }
  },
  computed: {
    resultQuery() {
      if (this.searchQuery) {
        return this.items.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.fields.toLowerCase().includes(v));
        });
      } else {
        return this.items;
      }
    }
  },
  mounted() {
    this.getDataTable();
    this.getDataTableAdvanced();
    this.getallprovince();
    this.getAllCustomer();
  },
  methods: {
    getallprovince() {
      WebService.getrefProv().then(async res => {
        const roles = res.data.data;
        let selectedOptions;
        selectedOptions = [];
        roles.map(i => {
          return selectedOptions.push({
            text: i.name,
            value: i.name
          });
        });
        this.optionprov = selectedOptions;
      });
    },
    getAllCustomer() {
      WebService.getRefCustomer().then(async res => {
        const roles = res.data.data;
        let selectedOptions;
        selectedOptions = [];
        roles.map(i => {
          return selectedOptions.push({
            text: i.name,
            value: i.id
          });
        });
        this.optionscustomer = selectedOptions;
      });
    },
    onSubmitEdit() {
      this.$v.formedit.$touch();
      if (this.$v.formedit.$anyError) {
        return;
      }

      let id = this.formedit.id;
      let data = {
        id: this.formedit.id,
        address: this.formedit.address,
        box_sn: this.formedit.boxsn,
        customer_id: parseInt(this.formedit.customer),
        district: this.formedit.district,
        location_name: this.formedit.location,
        pkt: this.formedit.pkt,
        province: this.formedit.province,
        site_class_name: this.formedit.classname,
        site_id: this.formedit.site_id,
        wsid: this.formedit.ws_id,
        zip_code: this.formedit.zip_code
      };

      console.log(data);
      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.editLocalsite(data, id).then(
            res => {
              console.log(res);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil Diubah",
                showConfirmButton: false,
                timer: 1500
              });
              this.getDataTableAdvanced();
              this.resetFormEdit();
              this.showModalEdit = false;
            },
            error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Error",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
              this.getDataTableAdvanced();
              this.showModalEdit = false;
            }
          );
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      let body = {
        address: this.form.address,
        box_sn: this.form.boxsn,
        customer_id: parseInt(this.form.customer),
        district: this.form.district,
        location_name: this.form.location,
        pkt: this.form.pkt,
        province: this.form.province,
        site_class_name: this.form.classname,
        site_id: this.form.site_id,
        wsid: this.form.ws_id,
        zip_code: this.form.zip_code
      };
      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          const user = JSON.parse(localStorage.getItem("user"));
          const headers = {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Authorization": `Bearer ${user.access_token}`
            }
          };
          Axios.post(`${process.env.VUE_APP_URL_LOCAL}/local-site-data`, body, headers)
          .then(() => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil ditambah",
                showConfirmButton: false,
                timer: 1500
              });
              this.getDataTableAdvanced();
              this.resetForm();
              this.showModalAdd = false;
          }).catch((error) => {
            console.log(error.response.data.message);
            Swal.fire({
                title: "Kesalahan",
                text: `Error ${error.response.data?.message ? error.response.data.message : ''}`,
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
              this.getDataTableAdvanced();
              // this.showModalAdd = false;
          });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    hapus(value) {
      const id = value.row.data.id;
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah Yakin Data Dihapus?.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "OK",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.deletelocalsite(id).then(
            res => {
              console.log(res);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil Dihapus",
                showConfirmButton: false,
                timer: 1500
              });
              this.getDataTableAdvanced();
              this.resetForm();
              this.showModalAdd = false;
            },
            error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Data Gagal Dihapus",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
              this.getDataTableAdvanced();
              this.showModalAdd = false;
            }
          );
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateStateFormEdit(name) {
      const { $dirty, $error } = this.$v.formedit[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        id: null,
        site_id: "",
        ws_id: "",
        location: "",
        boxsn: "",
        classname: "",
        address: "",
        province: "",
        district: "",
        zip_code: "",
        pkt: "",
        customer: ""
      };
      this.$nextTick(() => {
        this.$bvModal.hide("add-new-modal");
      });
    },
    resetFormEdit() {
      this.formEdit = {
        id: null,
        site_id: "",
        ws_id: "",
        location: "",
        boxsn: "",
        classname: "",
        address: "",
        province: "",
        district: "",
        zip_code: "",
        pkt: "",
        customer: ""
      };

      this.$nextTick(() => {
        this.$bvModal.hide("edit-user-modal");
      });
    },
    onchangeSiteId() {
      const filter = "site_id";
      const keyword = this.searchID ? this.searchID : "";
      if (this.searchID !== "") {
        var filteredData = this.itemsadvanced.filter(function(obj) {
          return obj[filter] === keyword;
        });
        this.itemsadvanced = filteredData;
      } else {
        this.getDataTableAdvanced();
      }
    },
    onchangeWSId() {
      const filter = "wsid";
      const keyword = this.wsid ? this.wsid : "";
      if (this.wsid !== "") {
        var filteredData = this.itemsadvanced.filter(function(obj) {
          return obj[filter] === keyword;
        });
        this.itemsadvanced = filteredData;
      } else {
        this.getDataTableAdvanced();
      }
    },
    onchangeLocation() {
      const filter = "location_name";
      const keyword = this.location ? this.location : "";
      if (this.location !== "") {
        var filteredData = this.itemsadvanced.filter(function(obj) {
          return obj[filter] === keyword;
        });
        this.itemsadvanced = filteredData;
      } else {
        this.getDataTableAdvanced();
      }
    },
    onCancel(){
      this.showModalAdd = false;
      this.resetForm();
    },
    onchangeBox() {
      const filter = "box_sn";
      const keyword = this.boxSn ? this.boxSn : "";
      if (this.boxSn !== "") {
        var filteredData = this.itemsadvanced.filter(function(obj) {
          return obj[filter] === keyword;
        });
        this.itemsadvanced = filteredData;
      } else {
        this.getDataTableAdvanced();
      }
    },
    onchangeClassName() {
      const filter = "site_class_name";
      const keyword = this.className ? this.className : "";
      if (this.className !== "") {
        var filteredData = this.itemsadvanced.filter(function(obj) {
          return obj[filter] === keyword;
        });
        this.itemsadvanced = filteredData;
      } else {
        this.getDataTableAdvanced();
      }
    },
    info(item, index, button) {
      console.log(item, index, button);
      this.infoModal.title = `Detail`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    infoadv(item, index, button) {
      console.log(item, index, button);
      this.infoModaladv.title = `Detail`;
      this.infoModaladv.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModaladv.id, button);
    },
    getDataTable() {
      let databody = { type: "basic" };
      WebService.getallSiteData(databody).then(
        res => {
          this.itemsuser = res.data.data;
          this.totalRows = res.data.totalCount;
        },
        error => {
          console.log(error);
          this.itemsuser = [];
          this.totalRows = 0;
          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    getDataTableAdvanced() {
      let databody = JSON.stringify([{ selector: "created_at", desc: true }]);
      WebService.getlocalsiteAll(encodeURI(databody)).then(
        res => {
          this.itemsadvanced = res.data.data;
          this.totalRows = res.data.totalCount;
        },
        error => {
          console.log(error);
          this.itemsadvanced = [];
          this.totalRows = 0;
          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    hapusvisit(index) {
      this.items.splice(index, 1);
    },
    iconEdit(e) {
      this.showModalEdit = true;
      const data = e.row.data;
      console.log(e.row.data);
      this.formedit.id = data.id;
      this.formedit.site_id = data.site_id;
      this.formedit.ws_id = data.wsid;
      this.formedit.location = data.location_name;
      this.formedit.boxsn = data.box_sn;
      this.formedit.classname = data.site_class_name;
      this.formedit.address = data.address;
      this.formedit.province = data.province;
      this.formedit.district = data.district;
      this.formedit.zip_code = data.zip_code;
      this.formedit.pkt = data.pkt;
      this.formedit.customer = data.customer_id;
    },
    iconAdvanced(e) {
      this.showModalEditadv = true;
      this.detaildataadv = e.row.data;
    }
  }
};
</script>

<style>
.btn-add {
  width: 144px;
  height: 44px;
  background: #c60b09;
  border-radius: 8px;
  color: #fffafa;
}
.btn-cancel {
  width: 144px;
  height: 44px;
  border: 1px solid #c60b09;
  box-sizing: border-box;
  border-radius: 8px;
  color: #c60b09;
}
</style>
