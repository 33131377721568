<template>
  <div class="container-fluid ml-10">
     <div>
      <Table_Sitedat />
    </div>
  </div>
</template>

<script>
import Table_Sitedat from "@/components/sitedata/Table_Sitedat.vue";
export default {
    components:{Table_Sitedat}
};
</script>

<style></style>
